<template>
  <b-modal id="modal-benefit" title="Viviendas disponibles" ok-only hide-footer no-close-on-backdrop>
    <b-card-text>
      <b-row class=" justify-content-center align-items-center">
        <b-col v-for="(item, index) in cart.contract" :key="index" cols="6" md="6" class="mb-2 ">
          <b-col md="12">
            <b-button :variant="cart.contractId == item.id ? 'success' : 'primary'" class="btn-add" @click="sendInfo(item)" :disabled="isABooking(item) || !!isLoading">
              Vivienda: {{item.code}} <br> {{ item.housingname }} <br>
            </b-button>
          </b-col>
        </b-col>
      </b-row>
      <b-row v-if="cart.currentBenefits.length!=0 && !isLoading" class="mt-2 justify-content-between align-items-center" >
        <b-col cols="12">
          <b-table
            :items="cart.currentBenefits"
            :fields="tableColumns"
            responsive
            small
            sticky-header="300"
            primary-key="id"
            class="custom-size"
          >
            <template #cell(vigency)="row">
              <span class="text-nowrap">
                <b>{{row.item.benefit.dateStart}}</b> <br>
                <b>{{row.item.benefit.dateEnd}}</b>
              </span>
            </template>
          </b-table>
        </b-col>
        <!-- <b-col  cols="12" class=" text-right">
          <b-button variant="primary" class="btn-add" @click="useBenefit()">
            usar
          </b-button>
        </b-col> -->
      </b-row>
      <div v-else-if="!!isLoading" class="text-center my-2">
        <b-spinner class="align-middle"/> <br> Cargando
      </div>
    </b-card-text>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [market],
  props: {
    setUseBenefit: {
      type: Function,
      required: true
    },
    getBenefits: {
      type: Function,
      required: true
    },
    resetEveryBenefit: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      numberHouse: "",
      idHotel: "",
      housing: [],
      isLoading:false,
      contractSelect:0,
      mqShallShowLeftSidebar: false,
      tableColumns: [
        { key:'benefitname', label: 'Nombre', formatter: (value, key, item) => {return item.benefit[key]}},
        { key:'benefitqty', label: 'Cantidad', formatter: (value, key, item) => {return item.benefit[key]}},
        { key:'balance', label: 'Disponible', formatter: (value, key, item) => {return item.benefit[key]}},
        { key:'used', label: 'Usado', formatter: (value, key, item) => {return item.benefit[key]}},
      ]
    }
  },
  computed: {
    ...mapState("shop", ["cart"]),
  },
  methods: {
    ...mapActions("fivesClubCatalogs", ["getHousingOwners", "fetchBenefitsByYearContract","getdiscountsContract"]),
    ...mapMutations("shop", ["setBenefits", "setMembershipsDiscount", "setHotelCliente", "setCurrentContractBenefitsList", "setCurrentContract"]),

    async sendInfo(item) {
      this.isLoading=item.id
      this.cart.contractId= item.id
      this.contractSelect=item.id

      await this.resetEveryBenefit()
      await this.getBenefits(item.id)

      this.setCurrentContract(item)
      this.setUseBenefit(item.id)

      this.setHotelCliente(item.resortsid)
      this.isLoading=false
    },
    async sendHousing() {
      const payload = {
        numberHouse: this.numberHouse,
        idHotel: this.idHotel,
      };
      const {data} = await this.getHousingOwners(payload);
      this.housing = data
    },
    isABooking(contract){
      const ownerbooking = this.cart.items.find(item=>item.categoryName=='ReservasOwners')
      if (ownerbooking) return ownerbooking?.contract != contract?.id
      else return false
    }
  },
};
</script>

<style lang="scss" scoped>
table.table.table-sm.table-striped {
  font-size: 12px;
}
table.table.table-sm.table-striped th {
  font-size: 12px;
}
.custom-size {
font-size: 12px !important;
}
.table-benefit{
  max-height:300px;
  overflow: auto;
}
.btn-add:focus, .btn-add:active{
  background:#000 ;
}
</style>
